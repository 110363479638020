/* Seta a primeira sessão */

.land {
    display: flex;
    flex-direction: row;
    background-image: url('./banner.png');
    min-height: 500px;
    max-height: 700px;
    background-size: cover;

}

.content {
    width: 50%;
    max-height: 100%;
    text-align: right;
    color: #223645;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.content h1 {
    margin: 0 10%;
}

@media screen and (min-width: 900px) {
    .land {
        min-height: 800px;
        background-size: cover;

    }
}



/* Quem somos */

.quem {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

#img-quem{
    display: block;
    margin-left: auto;
    padding: 15px 15px;
    max-width: 570px;
    max-height: 700px;
    width: auto;
    height: auto;
}
.content-quem {    
    max-width: 570px;
    max-height: 100%;
    padding-right: 30px;
    text-align: right;
    color: #223645;
    display: flex;
    justify-content: top;
    text-align: left;
    flex-grow: 1;
    flex-direction: column;
}

.content h1 {
    margin: 0 10%;
}

#button-quem {
    background-color: #0f6e87;
    width: 100%;
    margin: 0px auto;
    align-items: center;
    color: #ffffff;
}

#bt-wpp{
    display: flex;
    margin:0px ;
    width: 100%;
}

#button-quem:hover{
    background-color: #1ea097;
}

@media only screen and (max-width: 1000px) {
    .quem {
        flex-direction: column;

    }

    .content-quem {    
        max-width: 570px;
        max-height: 100%;
        padding: 10%;
        color: #223645;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-grow: 1;
        flex-direction: column;
    }

    #img-quem{
        margin: auto;
        align-items: center;
    }
}


/* Quem somos */
#faq-h1{
    text-align: center;
}

.faq {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

#img-faq{
    display: block;
    margin-left: auto;
    padding: 15px 15px;
    max-width: 570px;
    max-height: 600px;
    width: auto;
    height: auto;
}
.content-faq {    
    max-width: 570px;
    max-height: 100%;
    padding-right: 30px;
    text-align: right;
    color: #223645;
    display: flex;
    justify-content: top;
    text-align: left;
    flex-grow: 1;
    flex-direction: column;
}

.content h1 {
    margin: 0 10%;
}


@media only screen and (max-width: 1000px) {
    .faq {
        flex-direction: column;

    }

    .content-faq {    
        max-width: 570px;
        max-height: 100%;
        padding: 10%;
        color: #223645;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-grow: 1;
        flex-direction: column;
    }

    #img-faq{
        margin: auto;
        align-items: center;
    }
}

