/* Quem somos */

h3 {
    text-align: center;
}

.time {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.time-content {    
    max-width: 250px;
    color: #223645;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    flex-grow: 1;
    padding: 30px 15px;
}
.time-content img{
    max-width: 250px;
    margin: 0px auto;
}

.time-content h4 {
    padding: 15px 0px;
}


@media only screen and (max-width: 1000px) {
    .time {
        flex-direction: column;
        align-items: center;

    }

}