.carousel-card{
    display: flex;
    flex-direction: column;
}


.balao {
    background: #e6e9ec;
    padding: 15px;
    width: 80%;
    margin: auto;
}



.text{
    padding: 10px;
    margin-top: auto;
}