.CardHome{
    padding: 10px;
    margin: 5px 15px;
    text-align: center;
}

.CardHome Button{
    background-color: transparent;
    box-shadow: 0px 0px 0px 0px;
    color: #647589;
    display: flex;
    margin: auto;
}

.CardHome Button:hover{
    background-color: transparent;
    box-shadow: 0px 0px 0px 0px;
    color: #223645;
    justify-content: center;
}

.CardHome img{
    height: 150px;
    width: 150px;
}