/* hide disabled buttons */
.rec.rec-arrow:disabled {
    visibility: hidden;
}

.rec.rec-arrow {
    background-color: #0f6e87;
    border: 0px;
    color: #ffffff;
}
.rec.rec-arrow:hover {
    background-color: #1ea097;
    border: 0px;
}
.rec.rec-dot {
    background-color: #0f6e87;
    border: 0px;
    color: #ffffff;
    border-color: #0f6e87;
    box-shadow: #093b47;
}

.rec.rec-arrow:hover {
    background-color: #1ea097;
    border: 0px;
}
.rec.rec-arrow:focus {
    background-color: #1ea097;
    border: 0px;
}