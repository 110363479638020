/* Quem somos */

.about {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

#img-about{
    display: block;
    margin-left: auto;
    padding: 15px 15px;
    max-width: 570px;
    max-height: 700px;
    width: auto;
    height: auto;
}
.content-about {    
    max-width: 570px;
    max-height: 100%;
    padding-right: 30px;
    margin: 15px 0px;
    text-align: right;
    color: #223645;
    display: flex;
    justify-content: center;
    text-align: left;
    flex-grow: 1;
    flex-direction: column;
}

.content-about p{
    padding: 15px 0px;
}



.content h1 {
    margin: 0 10%;
}

#button-quem {
    background-color: #0f6e87;
    width: 70%;
    margin: 0px auto;
    color: #ffffff;
}

#button-quem:hover{
    background-color: #1ea097;
}

@media only screen and (max-width: 1000px) {
    .about {
        flex-direction: column;

    }

    .content-about {    
        max-width: 570px;
        max-height: 100%;
        padding: 10%;
        color: #223645;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-grow: 1;
        flex-direction: column;
    }

    #img-about{
        display: none;
    }
}

/* Sessão de consultoria */

.consultoria {
    background-color: #F4F9FC;
    padding: 25px 10px;
}

.consultoria-title h1 {
    text-align: center;
    color: #223645;
    justify-content: center;
}

.consultoria-title p {
    text-align: center;
    color: #223645;
    justify-content: center;
}

.consultoria-cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media only screen and (max-width: 800px) {
    .consultoria-cards {
      flex-direction:column;
    }
  }




/* Quem somos */
#faq-h1{
    text-align: center;
}

.faq {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

#img-faq{
    display: block;
    margin-left: auto;
    padding: 15px 15px;
    max-width: 570px;
    max-height: 600px;
    width: auto;
    height: auto;
}
.content-faq {    
    max-width: 570px;
    max-height: 100%;
    padding-right: 30px;
    text-align: right;
    color: #223645;
    display: flex;
    justify-content: top;
    text-align: left;
    flex-grow: 1;
    flex-direction: column;
}

.content h1 {
    margin: 0 10%;
}


@media only screen and (max-width: 1000px) {
    .faq {
        flex-direction: column;

    }

    .content-faq {    
        max-width: 570px;
        max-height: 100%;
        padding: 10%;
        color: #223645;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-grow: 1;
        flex-direction: column;
    }

    #img-faq{
        margin: auto;
        align-items: center;
    }
}

