/* Sessão de consultoria */

.consultoria {
    background-color: #f4f9fc;
    padding: 25px 10px;
}

.consultoria-title h1 {
    text-align: center;
    color: #223645;
    justify-content: center;
}

.consultoria-title p {
    text-align: center;
    color: #223645;
    justify-content: center;
}

.consultoria-cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media only screen and (max-width: 800px) {
    .consultoria-cards {
      flex-direction:column;
      align-items: center;
    }
  }


