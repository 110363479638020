.footer-geral{
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #206f87;
    color: #ffffff;

}

.bottom-footer a{
    margin: 15px;
}
.bottom-footer p{
    padding: 15px;
}

.footer {
    display: flex;
    flex-direction: row;
    min-height: 400px;
    max-height: 700px;

}

.footer img{
    max-height: 80px;
    padding: 15px 0px;
}

.content-footer {
    width: 50%;
    max-height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.content-footer h1 {
    margin: 0 10%;
}

@media screen and (min-width: 900px) {
    .footer {
        min-height: 600px;
        background-size: cover;

    }
}

.contact-info{
    display: flex;
    flex-direction: row;
    color: #ffffff;
    padding: 10px 5px;
}

.contact-info span{
    padding: 0px 10px;
}
.contact-info svg{
    color: #ffffff;
}
