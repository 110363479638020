.navbar-img{
    max-height: 60px;
    margin-right: auto;
    padding: 15px 10px;
    align-items: left;
}

div.MuiBox-root.css-i9gxme > header > div{
    background-color: #ffffff;
}

div.MuiBox-root.css-i9gxme > header > div > button {
    color: #647589;
}

.button{
    background-color: #ffffff;
    color: #647589;
}

.bt-link{
    color:#647589;
}

.bt-link-nav{
    width: 100%;

}

@media only screen and (max-width: 1000px) {
    .navbar-img {
        display: none;

    }

    div.MuiBox-root.css-i9gxme > header > div > button {
        color: #647589;
        font-size: 8px;
    }
}