/* Quem somos */

.missao {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.missao-content {    
    max-width: 300px;
    color: #223645;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    flex-grow: 1;
    padding: 30px 0px;
}
.missao-content img{
    max-width: 150px;
    margin: 0px auto;
}

.missao-content h4 {
    padding: 15px 0px;
}


@media only screen and (max-width: 1000px) {
    .missao {
        flex-direction: column;
        align-items: center;

    }

}