/* Agende */

.agende {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    background-color: #22303b;
    padding: 50px auto;
    min-height: 400px;
    color: #ffffff
}
.agende a{
    min-width: 200px;
}

#envieMensagem {
    background-color: #0f6e87;
    width: 100%;
    margin: 50px auto;
    padding: 15px 0px;
    color: #ffffff;
}
#envieMensagem:hover{
    background-color:#1ea097
}
.agende strong{
    color:#206f87
}
